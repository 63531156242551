import React from 'react'

import { emojiFor, Species } from '../../lib/helpers'
import RadioButton from './RadioButton'

interface Props {
  species: Species
  setSpecies: (species: Species) => void
}

export default function SpeciesSelect(props: Props) {
  return (
    <div>
      <p className="bold text-m gray8 mb-0">Species</p>

      <RadioButton
        onClick={() => props.setSpecies(Species.Dog)}
        checked={props.species === Species.Dog}
        label={'Dogs ' + emojiFor(Species.Dog)}
      />

      <RadioButton
        onClick={() => props.setSpecies(Species.Cat)}
        checked={props.species === Species.Cat}
        label={'Cats ' + emojiFor(Species.Cat)}
      />
    </div>
  )
}
